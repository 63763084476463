


  import dep0 from "ember-modifier/-embroider-implicit-modules.js";
  import dep1 from "ember-primitives/-embroider-implicit-modules.js";
  import dep2 from "ember-resources/-embroider-implicit-modules.js";
  import dep3 from "ember-route-template/-embroider-implicit-modules.js";
  import dep4 from "tracked-built-ins/-embroider-implicit-modules.js";
  import dep5 from "tracked-toolbox/-embroider-implicit-modules.js";
  import dep6 from "ember-modifier/-embroider-implicit-modules.js";
  import dep7 from "ember-page-title/-embroider-implicit-modules.js";
  import dep8 from "ember-qunit/-embroider-implicit-modules.js";
  import dep9 from "tracked-built-ins/-embroider-implicit-modules.js";
  import dep10 from "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
  import dep11 from "@ember/optional-features/-embroider-implicit-modules.js";
  import dep12 from "@ember/string/-embroider-implicit-modules.js";
  import dep13 from "@embroider/macros/-embroider-implicit-modules.js";
  import dep14 from "ember-auto-import/-embroider-implicit-modules.js";
  import dep15 from "ember-cli-htmlbars/-embroider-implicit-modules.js";
  import dep16 from "@ember/test-helpers/-embroider-implicit-modules.js";
  import dep17 from "@glimmer/component/-embroider-implicit-modules.js";
  import dep18 from "@glimmer/tracking/-embroider-implicit-modules.js";
  import dep19 from "ember-template-imports/-embroider-implicit-modules.js";
  import dep20 from "ember-cli-babel/-embroider-implicit-modules.js";
  import dep21 from "ember-load-initializers/-embroider-implicit-modules.js";
  import dep22 from "ember-resolver/-embroider-implicit-modules.js";
  import dep23 from "ember-source/-embroider-implicit-modules.js";
  import dep24 from "loader.js/-embroider-implicit-modules.js";


export default Object.assign({},
    dep0,
    dep1,
    dep2,
    dep3,
    dep4,
    dep5,
    dep6,
    dep7,
    dep8,
    dep9,
    dep10,
    dep11,
    dep12,
    dep13,
    dep14,
    dep15,
    dep16,
    dep17,
    dep18,
    dep19,
    dep20,
    dep21,
    dep22,
    dep23,
    dep24,
  {
  }
);
