
module.exports = new Proxy({}, {
  get(target, prop) {

    if (prop === '__esModule') {
      return true;
    }

      const m = window.require("@glimmer/tracking");

    if (prop === 'default' && !m.__esModule && !m.default) {
      return m;
    }

    return m[prop];
  }
});
